import * as React from 'react';
import { SubMenu as RcSubMenu } from 'rc-menu';
import classNames from 'classnames';
import omit from 'rc-util/lib/omit';
import MenuContext from './MenuContext';
import { isValidElement } from '../_util/reactNode';
class SubMenu extends React.Component {
    renderTitle(inlineCollapsed) {
        const { icon, title, level, rootPrefixCls } = this.props;
        if (!icon) {
            return inlineCollapsed && level === 1 && title && typeof title === 'string' ? (<div className={`${rootPrefixCls}-inline-collapsed-noicon`}>{title.charAt(0)}</div>) : (title);
        }
        // inline-collapsed.md demo 依赖 span 来隐藏文字,有 icon 属性，则内部包裹一个 span
        // ref: https://github.com/ant-design/ant-design/pull/23456
        const titleIsSpan = isValidElement(title) && title.type === 'span';
        return (<>
        {icon}
        {titleIsSpan ? title : <span>{title}</span>}
      </>);
    }
    render() {
        const { rootPrefixCls, popupClassName } = this.props;
        return (<MenuContext.Consumer>
        {({ inlineCollapsed, antdMenuTheme }) => (<RcSubMenu {...omit(this.props, ['icon'])} title={this.renderTitle(inlineCollapsed)} popupClassName={classNames(rootPrefixCls, `${rootPrefixCls}-${antdMenuTheme}`, popupClassName)}/>)}
      </MenuContext.Consumer>);
    }
}
SubMenu.contextType = MenuContext;
// fix issue:https://github.com/ant-design/ant-design/issues/8666
SubMenu.isSubMenu = 1;
export default SubMenu;
