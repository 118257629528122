import CalendarLocale from 'rc-picker/lib/locale/en_GB';
import TimePickerLocale from '../../time-picker/locale/en_GB';
// Merge into a locale object
const locale = {
    lang: Object.assign({ placeholder: 'Select date', yearPlaceholder: 'Select year', quarterPlaceholder: 'Select quarter', monthPlaceholder: 'Select month', weekPlaceholder: 'Select week', rangePlaceholder: ['Start date', 'End date'], rangeYearPlaceholder: ['Start year', 'End year'], rangeMonthPlaceholder: ['Start month', 'End month'], rangeWeekPlaceholder: ['Start week', 'End week'] }, CalendarLocale),
    timePickerLocale: Object.assign({}, TimePickerLocale),
};
// All settings at:
// https://github.com/ant-design/ant-design/blob/master/components/date-picker/locale/example.json
export default locale;
