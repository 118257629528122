var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import { ConfigContext } from '../config-provider';
import RowContext from './RowContext';
import { tuple } from '../_util/type';
import ResponsiveObserve, { responsiveArray, } from '../_util/responsiveObserve';
import useFlexGapSupport from './hooks/useFlexGapSupport';
const RowAligns = tuple('top', 'middle', 'bottom', 'stretch');
const RowJustify = tuple('start', 'end', 'center', 'space-around', 'space-between');
const Row = React.forwardRef((props, ref) => {
    const { prefixCls: customizePrefixCls, justify, align, className, style, children, gutter = 0, wrap } = props, others = __rest(props, ["prefixCls", "justify", "align", "className", "style", "children", "gutter", "wrap"]);
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const [screens, setScreens] = React.useState({
        xs: true,
        sm: true,
        md: true,
        lg: true,
        xl: true,
        xxl: true,
    });
    const supportFlexGap = useFlexGapSupport();
    const gutterRef = React.useRef(gutter);
    // ================================== Effect ==================================
    React.useEffect(() => {
        const token = ResponsiveObserve.subscribe(screen => {
            const currentGutter = gutterRef.current || 0;
            if ((!Array.isArray(currentGutter) && typeof currentGutter === 'object') ||
                (Array.isArray(currentGutter) &&
                    (typeof currentGutter[0] === 'object' || typeof currentGutter[1] === 'object'))) {
                setScreens(screen);
            }
        });
        return () => ResponsiveObserve.unsubscribe(token);
    }, []);
    // ================================== Render ==================================
    const getGutter = () => {
        const results = [0, 0];
        const normalizedGutter = Array.isArray(gutter) ? gutter : [gutter, 0];
        normalizedGutter.forEach((g, index) => {
            if (typeof g === 'object') {
                for (let i = 0; i < responsiveArray.length; i++) {
                    const breakpoint = responsiveArray[i];
                    if (screens[breakpoint] && g[breakpoint] !== undefined) {
                        results[index] = g[breakpoint];
                        break;
                    }
                }
            }
            else {
                results[index] = g || 0;
            }
        });
        return results;
    };
    const prefixCls = getPrefixCls('row', customizePrefixCls);
    const gutters = getGutter();
    const classes = classNames(prefixCls, {
        [`${prefixCls}-no-wrap`]: wrap === false,
        [`${prefixCls}-${justify}`]: justify,
        [`${prefixCls}-${align}`]: align,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    // Add gutter related style
    const rowStyle = {};
    const horizontalGutter = gutters[0] > 0 ? gutters[0] / -2 : undefined;
    const verticalGutter = gutters[1] > 0 ? gutters[1] / -2 : undefined;
    if (horizontalGutter) {
        rowStyle.marginLeft = horizontalGutter;
        rowStyle.marginRight = horizontalGutter;
    }
    if (supportFlexGap) {
        // Set gap direct if flex gap support
        [, rowStyle.rowGap] = gutters;
    }
    else if (verticalGutter) {
        rowStyle.marginTop = verticalGutter;
        rowStyle.marginBottom = verticalGutter;
    }
    const rowContext = React.useMemo(() => ({ gutter: gutters, wrap, supportFlexGap }), [
        gutters,
        wrap,
        supportFlexGap,
    ]);
    return (<RowContext.Provider value={rowContext}>
      <div {...others} className={classes} style={Object.assign(Object.assign({}, rowStyle), style)} ref={ref}>
        {children}
      </div>
    </RowContext.Provider>);
});
Row.displayName = 'Row';
export default Row;
