import Pagination from 'rc-pagination/lib/locale/az_AZ';
import DatePicker from '../date-picker/locale/az_AZ';
import TimePicker from '../time-picker/locale/az_AZ';
import Calendar from '../calendar/locale/az_AZ';
const localeValues = {
    locale: 'az',
    Pagination,
    DatePicker,
    TimePicker,
    Calendar,
    Table: {
        filterTitle: 'Filter menyu',
        filterConfirm: 'Axtar',
        filterReset: 'Sıfırla',
        emptyText: 'Məlumat yoxdur',
        selectAll: 'Cari səhifəni seç',
        selectInvert: 'Invert current page',
    },
    Modal: {
        okText: 'Bəli',
        cancelText: 'Ləğv et',
        justOkText: 'Bəli',
    },
    Popconfirm: {
        okText: 'Bəli',
        cancelText: 'Ləğv et',
    },
    Transfer: {
        titles: ['', ''],
        notFoundContent: 'Tapılmadı',
        searchPlaceholder: 'Burada axtar',
        itemUnit: 'item',
        itemsUnit: 'items',
    },
    Select: {
        notFoundContent: 'Tapılmadı',
    },
    Upload: {
        uploading: 'Yüklənir...',
        removeFile: 'Faylı sil',
        uploadError: 'Yükləmə xətası',
        previewFile: 'Fayla önbaxış',
    },
};
export default localeValues;
