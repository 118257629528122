var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import SwapRightOutlined from '@ant-design/icons/SwapRightOutlined';
import { RangePicker as RCRangePicker } from 'rc-picker';
import enUS from '../locale/en_US';
import { ConfigContext } from '../../config-provider';
import SizeContext from '../../config-provider/SizeContext';
import LocaleReceiver from '../../locale-provider/LocaleReceiver';
import { getRangePlaceholder } from '../util';
import { getTimeProps, Components } from '.';
export default function generateRangePicker(generateConfig) {
    class RangePicker extends React.Component {
        constructor() {
            super(...arguments);
            this.pickerRef = React.createRef();
            this.focus = () => {
                if (this.pickerRef.current) {
                    this.pickerRef.current.focus();
                }
            };
            this.blur = () => {
                if (this.pickerRef.current) {
                    this.pickerRef.current.blur();
                }
            };
            this.getDefaultLocale = () => {
                const { locale } = this.props;
                const result = Object.assign(Object.assign({}, enUS), locale);
                result.lang = Object.assign(Object.assign({}, result.lang), (locale || {}).lang);
                return result;
            };
            this.renderPicker = (locale) => {
                const { getPrefixCls, direction, getPopupContainer } = this.context;
                const _a = this.props, { prefixCls: customizePrefixCls, getPopupContainer: customGetPopupContainer, className, size: customizeSize, bordered = true, placeholder } = _a, restProps = __rest(_a, ["prefixCls", "getPopupContainer", "className", "size", "bordered", "placeholder"]);
                const { format, showTime, picker } = this.props;
                const prefixCls = getPrefixCls('picker', customizePrefixCls);
                let additionalOverrideProps = {};
                additionalOverrideProps = Object.assign(Object.assign(Object.assign({}, additionalOverrideProps), (showTime ? getTimeProps(Object.assign({ format, picker }, showTime)) : {})), (picker === 'time' ? getTimeProps(Object.assign(Object.assign({ format }, this.props), { picker })) : {}));
                const rootPrefixCls = getPrefixCls();
                return (<SizeContext.Consumer>
          {size => {
                        const mergedSize = customizeSize || size;
                        return (<RCRangePicker separator={<span aria-label="to" className={`${prefixCls}-separator`}>
                    <SwapRightOutlined />
                  </span>} ref={this.pickerRef} placeholder={getRangePlaceholder(picker, locale, placeholder)} suffixIcon={picker === 'time' ? <ClockCircleOutlined /> : <CalendarOutlined />} clearIcon={<CloseCircleFilled />} allowClear transitionName={`${rootPrefixCls}-slide-up`} {...restProps} {...additionalOverrideProps} className={classNames({
                                [`${prefixCls}-${mergedSize}`]: mergedSize,
                                [`${prefixCls}-borderless`]: !bordered,
                            }, className)} locale={locale.lang} prefixCls={prefixCls} getPopupContainer={customGetPopupContainer || getPopupContainer} generateConfig={generateConfig} prevIcon={<span className={`${prefixCls}-prev-icon`}/>} nextIcon={<span className={`${prefixCls}-next-icon`}/>} superPrevIcon={<span className={`${prefixCls}-super-prev-icon`}/>} superNextIcon={<span className={`${prefixCls}-super-next-icon`}/>} components={Components} direction={direction}/>);
                    }}
        </SizeContext.Consumer>);
            };
        }
        render() {
            return (<LocaleReceiver componentName="DatePicker" defaultLocale={this.getDefaultLocale}>
          {this.renderPicker}
        </LocaleReceiver>);
        }
    }
    RangePicker.contextType = ConfigContext;
    return RangePicker;
}
